<template>
  <div class="task-manage">
  <comp-part title="待审核患者（审核注册患者信息）"></comp-part>
    <table-comp
    :table-opts="tableOpts"
    :handle-opts="handleOpts"
    :data="tableData"
    :page="currentPage"
    :total="totalPage"
    :page-size="pageSize"
    :open-handle="true"
    :show-number="true"
    @other_1="handleCheck"
    @handleSizeChange="sizeChange"
    @handleCurrentChange="pageChange"
    ></table-comp>

  </div>
</template>

<script>
import TableComp from '@/components/Table/Table'
import CompPart from '@/components/Part'
import { getAwaitFollowList } from '@/service/module/task'
export default {
  components: {
    TableComp,
    CompPart
  },
  data () {
    return {
      handleOpts: { // 操作列表设置
        other_1: {
          type: 'text',
          text: '审核患者信息'
        }
      },
      // isOpenHandle: true,
      tableOpts: [ // 列表表头设置
        {
          label: '患者姓名',
          prop: 'name',
          render: (h, params) => {
            const name = params.row.name
            if (!name) {
              return h('span', {}, '-')
            } else {
              return h('span', {}, name)
            }
          }
        },
        {
          label: '性别',
          prop: 'gender',
          render: (h, params) => {
            const gender = params.row.gender
            if (!gender) {
              return h('span', {}, '-')
            }
            let genderText = ''
            switch (gender) {
              case '2' : genderText = '女'
                break
              case '1' : genderText = '男'
                break
            }
            return h('span', {}, genderText)
          }
        },
        {
          label: '病历号',
          prop: 'medicalRecordNumber'
        },
        {
          label: '注册时间',
          prop: 'createAt',
          render: (h, params) => {
            if (!params.row.createAt) {
              return h('span', {}, '-')
            }
            var moment = require('moment')
            const createAt = moment.unix(params.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')
            return h('span', {}, createAt)
          }
        }
      ],
      tableData: [], // 待处理任务列表数据
      totalPage: 0, // 总条数
      currentPage: 1, // 当前条数
      pageSize: 20 // 每页条数
    }
  },
  mounted () {
    this._send('$pageView', '待审核患者进入情况', '待审核患者页面 加载成功时触发', {
      accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
      doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
      hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
    })
    this.getDataList(1, this.pageSize)
  },
  methods: {
    async getDataList (pageNum, pageSize) {
      try {
        const res = await getAwaitFollowList({
          pageNum: pageNum,
          pageSize: pageSize
        })
        if (res) {
          this.tableData = res.data.results
          this.totalPage = res.data.total
          this.currentPage = res.data.currentPage
          this.pageSize = res.data.pageSize
        }
      } catch (e) {
        console.log('awaitList-err:', e)
      }
    },
    async handleCheck (data) {
      this.$router.push({
        name: 'reviewPatientInfo',
        query: {
          id: data.id
        }
      })
    },
    sizeChange (val) {
      this.pageSize = val
      this.getDataList(this.currentPage, val)
    },
    pageChange (val) {
      this.currentPage = val
      this.getDataList(val, this.pageSize)
    }
  }
}
</script>

<style scoped lang="scss">
.task-manage {
  padding: 0 17px;
}
.patient-info {
  margin: 0 20px;
  display: flex;
  flex-direction: row;
}

</style>
