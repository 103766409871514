import { get, post } from '@http/axios'

// 获取待处理任务列表
export const getAwaitFollowList = (params) => get(`/api/doctor-platform/followup/patient-audit/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`)

// 获取校对数据列表
export const getProofreadDataList = (params) => get(`/api/doctor-platform/followup/cied-report/audit/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`)

// 患者审核通过
export const confirmPatientReview = (params) => post('/api/doctor-platform/followup/patient-audit/confirm', params)

// 患者审核驳回
export const rejectPatientReview = (params) => post('/api/doctor-platform/followup/patient-audit/reject', params)
